/* You can add global styles to this file, and also import other style files */
@import '@fontsource/inter/variable.css';
// @import url('https://fonts.googleapis.com/css2?family=Inter:slnt,wght@-10..0,100..900&display=swap');
// @import url('https://rsms.me/inter/inter.css');
// html {
//   /* this enables some alternative styles and contextual characters to the font */
//   font-feature-settings: 'ss01', 'ss02', 'case', 'salt';
// }

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

span.sub-info {
  color: dimgray;
  font-size: 0.85em;
}

table {
  border-collapse: collapse;
}

a.link {
  display: inline-block;
  text-decoration: none;
  margin: 0.1rem 0.25rem;
  padding: 0.1rem 0.5rem;
  border-radius: 0.25rem;
  
  &.myesim {
    color: white;
    background-color: #e573aa;
    border: 1px solid #e573aa;

    &.disabled {
      color: gray;
      background-color: lightgray;
      border: 1px solid lightgray;
    }
  }

  &.checkfront {
    color: white;
    background-color: #1275b8;
    border: 1px solid #1275b8;
  }

  &.iij {
    color: white;
    background-color: #990000;
    border: 1px solid #990000;
  }
}

// for simple notification snack bar
.mat-mdc-snack-bar-container {
  display: flex;
  justify-content: space-between;
  div.message {
    display: inline;
  }
  div.action {
    display: inline;
    button {
      border: none;
      display: inline;
      margin-left: 0.25rem;
    }
  }
  &.info {
    --mdc-snackbar-container-color: lime;
    --mat-mdc-snack-bar-button-color: lime;
    --mdc-snackbar-supporting-text-color: darkslategray;
    button {
      color: darkslategray;
      background-color: lime;
    }
  }
  &.warn {
    --mdc-snackbar-container-color: yellow;
    --mat-mdc-snack-bar-button-color: yellow;
    --mdc-snackbar-supporting-text-color: darkslategray;
    button {
      color: darkslategray;
      background-color: yellow;
    }
  }
  &.error {
    --mdc-snackbar-container-color: orangered;
    --mat-mdc-snack-bar-button-color: orangered;
    --mdc-snackbar-supporting-text-color: darkslategray;
    button {
      color: darkslategray;
      background-color: orangered;
    }
  }
  &.fatal {
    border: 4px solid red;
    border-radius: 6px;
    --mdc-snackbar-container-color: black;
    --mat-mdc-snack-bar-button-color: black;
    --mdc-snackbar-supporting-text-color: white;
    button {
      color: white;
      background-color: black;
    }
  }
}

.mat-mdc-raised-button:not(:disabled) {
  &.button.sakura {
    background-color:#e573aa;
  }
}
